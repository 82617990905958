import { useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Card, Col, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import CustomTable from "./CustomTable";
import ExportRecords from "./ExportRecords";
import Graph from "./Graph";
import DashboardCards from "./DashboardCards";

export default function Dashboard() {
    const axiosPrivate = useAxiosPrivate();

    //logic for page load
    const [loading, setLoading] = useState(true);

    //logic for table
    const [code, setCode] = useState();
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableData(data.filter(obj => obj.code == code));
    }, [code, data]);

    const handleClick = (code) => {
        setCode(code);
    };
    
    //logic for graph
    const [graph, setGraph] = useState({});
    const [selectedMonth, setSelectedMonth] = useState('All');

    const handleChangeDataset = event => {        
        setSelectedMonth(event.target.value)   
    };

    //logic for cards
    const [cardDictionary, setCardDictionary] = useState({});

    const hours = {
        "4": 18, //T1 - Facility Training
        "5": 192, //T5 - Existing Driver/Operator Training
        "7": 12, //T3.2 - Officer Continuing Education
        "10": 12, //T5 - Existing Driver/Operator Training
        "11": 6, //T6 - Hazardous Materials Training
        "12": 240, //T7 - Recruit Training Program
        "196": 24, //Fire Inspector
        "197": 10, //Public Educator
        "198": 40, //Fire Investigator
    }

    const getData = async () => {
        try{
            var start = moment().startOf('year').format('YYYY-MM-DD');
            var end = moment().endOf('year').format('YYYY-MM-DD');
            await axiosPrivate.get(`/api/analytics/isorecords/preview?startDate=${start}&endDate=${end}`)
            .then(async response =>{
                const dictionary = response.data.reduce((obj, item) => {
                    obj[item.code] =
                    {
                        hoursRequired: item.id in hours ? hours[item.id]  : 0,
                        hoursCompleted: item.value,
                        percentageCompleted: item.id in hours ? Math.min(item.value / hours[item.id] * 100, 100) : 100
                    };
                    return obj;
                  }, {});

                setCardDictionary(dictionary);

                const allCodes = response.data.map(obj => obj.code);

                setCode(allCodes[0]);                

                await axiosPrivate.get(`/api/analytics/isorecords?startDate=${start}&endDate=${end}`)
                .then(response =>{
                    const allMonths = {
                        Jan: 0,
                        Feb: 0,
                        Mar: 0,
                        Apr: 0,
                        May: 0,
                        Jun: 0,
                        Jul: 0,
                        Aug: 0,
                        Sep: 0,
                        Oct: 0,
                        Nov: 0,
                        Dec: 0
                    };
    
                    const codes = allCodes.reduce((obj, key) => {
                        obj[key] = 0;
                        return obj;
                    }, {});
                    
                    let monthlyCodes = {
                        All: { ...allMonths },
                        Jan: { ...codes },
                        Feb: { ...codes },
                        Mar: { ...codes },
                        Apr: { ...codes },
                        May: { ...codes },
                        Jun: { ...codes },
                        Jul: { ...codes },
                        Aug: { ...codes },
                        Sep: { ...codes },
                        Oct: { ...codes },
                        Nov: { ...codes },
                        Dec: { ...codes }
                    };                    

                    response.data.forEach(record => {
                        const month = moment(record.completionDate).format('MMM');
                        monthlyCodes['All'][month] += record.hours;
                        const code = record.code;
                        monthlyCodes[month][code] += record.hours;                     
                    });
    
                    setData(response.data);
    
                    setGraph(monthlyCodes);
                    
                    setLoading(false);
                });
            })
        }
        catch(err){
            console.log(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);


    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
        <div className="iso-dashboard-container expanded-version-dashboard-container">
            <div className="iso-dashboard-content-wrapper expanded-version-content-wrapper">
                <h3 className="iso-dashboard-title expanded-version-title">Iso Dashboard</h3>
                
            <Row>                
                <DashboardCards data={cardDictionary} onClick={handleClick}/>
            </Row>
            <Row>                
                <Col xs="6">                                  
                    <CustomTable data={tableData}/>
                    <ExportRecords data={data}/>
                </Col>
                <Col xs="6">
                <Card className="iso-dashboard-graph-container">
                        <div className="iso-dashboard-graph-top-area">
                            <h4 className="iso-dashboard-graph-title">Hours Completed</h4>
                            <div className="iso-dashboard-data-selector-button">
                                <select value={selectedMonth} onChange={handleChangeDataset}>
                                    <option value="All">All</option>
                                    <option value="Jan">Jan</option>
                                    <option value="Feb">Feb</option>
                                    <option value="Mar">Mar</option>
                                    <option value="Apr">Apr</option>
                                    <option value="May">May</option>
                                    <option value="Jun">Jun</option>
                                    <option value="Jul">Jul</option>
                                    <option value="Aug">Aug</option>
                                    <option value="Sep">Sep</option>
                                    <option value="Oct">Oct</option>
                                    <option value="Nov">Nov</option>
                                    <option value="Dec">Dec</option>
                                </select>
                            </div>
                        </div>
                        <Graph data={graph[selectedMonth]}/>
                    </Card> 
                </Col>      
            </Row>
        </div>
        </div>   
    );
}
