import React from 'react';
import { Button } from 'reactstrap';
import * as XLSX from 'xlsx';

export default function ExportRecords (props) {
  const handleExportExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert records to worksheet
    const worksheet = XLSX.utils.json_to_sheet(props.data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Records');

    // Generate a file name
    const fileName = 'records.xlsx';

    // Generate a Blob object containing the workbook data
    const workbookData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([workbookData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link element
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    // Simulate a click on the download link to trigger the download
    downloadLink.click();

    // Clean up resources
    URL.revokeObjectURL(downloadLink.href);
  };

  return (
    <div>
      <Button onClick={handleExportExcel} className='export-button'>Export</Button>
    </div>
  );
};
