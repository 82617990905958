import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

export default function CustomTable(props) {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const displayedData = props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    
    return (
        <div className="iso-dashboard-data-table-container">
        <TableContainer component={Paper} className="iso-dashboard-data-table-wrapper">
            <Table className="iso-dashboard-data-table">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Completion Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {displayedData.map((row, index) => (
                <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.hours}</TableCell>
                    <TableCell>{row.completionDate.replace("T"," ")}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
        <br/>
        </div>
    );
};