import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
    const { auth } = useAuth();

    return (
        auth?.role == "authorized" ? <Outlet /> : <Navigate to="/unauthorized" />
    );
}

export default RequireAuth;