import { Container } from 'reactstrap';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'

export default function Graph (props) {
    
    const data = {
        labels: Object.keys(props.data),
        datasets: [
            {
            data: Object.values(props.data),
            backgroundColor: [
                '#212a5c'
              ]
            },
        ],
    };

    const options = {
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      },
      plugins: {
          legend: {
              display: false
          }
      }        
    };
  
    return (
      <Container>
        <Bar data={data} options={options} style={{ height: "100%", width: "100%" }}/>
      </Container>
    );
  };
  