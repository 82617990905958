import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';

const LOGIN_URL = '/api/auth/login';

const TryLogin = async (searchParams, auth, setAuth, navigate) => {
  if(auth?.role != "authorized"){
    try {
       await axios.post(LOGIN_URL,
           JSON.stringify({
               userid: searchParams.get("user_id"),
               username: searchParams.get("username"),
               authcode: searchParams.get("auth_code"),
               hash: searchParams.get("hash")
           })
       )
       .then(response => {
         setAuth({ userId: searchParams.get("user_id"), role: "authorized", accessToken: response?.data.accessToken });
       });
  }
  catch(err)
    {
        console.log(err);
        navigate('/unauthorized');
    }
  }  
  const redirect = searchParams.get("redirect");
  if(redirect == undefined){
    navigate('/');
  }
  else{
    navigate(`/${redirect}`);
  } 
}  

function Login() {
  const { auth, setAuth } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  
  useEffect(() => { 
    TryLogin(searchParams, auth, setAuth, navigate);
  }, [searchParams])
}

export default Login;
