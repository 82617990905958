import { React } from "react";
import '../iso-dashboard.css'
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const DASHBOARD_PATH = process.env.REACT_APP_DASHBOARD_PATH;

export default function PreviewCards({ data }) {
    let navigate = useNavigate();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const getTargetDomain = async () => {
        try {
            let domain = '';
            await axiosPrivate.post(`/api/core/domains/usersdomains`, { userIds: [auth.userId] })
                .then(response => {
                    domain = response.data[0].domain;
                });

            //Docebo multi-domain returns only the domain. The default value includes the https://
            if (domain.substring(0, 5) != 'https') {
                domain = `https://${domain}/`;
            }
            return domain;
        }
        catch (err) {
            console.log(err);
            navigate('/unauthorized')
        }
    };

    const handleButtonClick = async () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            navigate = navigate(DASHBOARD_PATH);
        }
        else {
            const domain = await getTargetDomain();
            window.parent.location.href = domain + DASHBOARD_PATH;
        }
    };

  const getPercentage = (value) => {
    return value.percentageCompleted.toFixed(0);
  }

  return (
    <div>
      <div className="iso-dashboard-container">
        <div className="iso-dashboard-content-wrapper">
          <h3 className="iso-dashboard-title">Iso Dashboard</h3>
          <div className="iso-dashboard-tiles-container">
            {Object.entries(data).map(([key, value]) => (
              <div key={key} className="iso-dashboard-tile">

                <div className="iso-dashboard-tile-overlay">
                  <span className="overlay-hours-completed">{value.hoursCompleted} Hours Completed</span>
                  <Button onClick={handleButtonClick} className="overlay-view-hours">
                    View Hours Completed
                  </Button>
                </div>

                {value.hoursRequired !== 0 ? (

                  // With percentage against a requirement
                  <div>
                    <h4 className="iso-dashboard-tile-title">{key}</h4>
                    <div className="iso-dashboard-tile-progress">
                      <div className="iso-dashboard-title-progressBar-outer">
                        <div className="iso-dashboard-title-progressBar-progress" style={{ width: `${value.percentageCompleted}%` }}></div>
                      </div>
                      <span className="iso-dashboard-tile-progress-percentage">{getPercentage(value)}%</span>
                    </div>
                  </div>

                ) : (
                  
                  // Without a percentage - no requirement
                  <div key={key} className={`expanded-version}`}>
                    <div className="iso-dashboard-expanded-text-content">
                      <h4 className="iso-dashboard-tile-title">{key}</h4>
                      <span className="iso-dashboard-hours-completed">{value.hoursCompleted} Hours Completed</span>
                    </div>
                  </div>

                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};